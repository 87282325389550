import * as React from 'react';

interface Props {
  onClick: any
  className: string
  buttonName: string
  buttonText: string
  type: 'submit' | 'reset' | 'button'
  children?: React.ReactNode
  active?: boolean
}

export const Button = (props: Props) => {

  const [disabled, setDisabled] = React.useState(false)

  async function onClickAction(event: any){
    if (props.onClick == undefined) return

    setDisabled(true)
    await props.onClick(event)
    setDisabled(false)
  }

  const disabledStatus = disabled || props.active == false

  return (
    <>
      <input
        // 親要素のdivで指定する必要あり また、divをここで描くのもありだけど切り替えはDomRendorで切り替える必要あり
        // style={disabledStatus ? {cursor: "not-allowed"} : {cursor: "pointer"}}
        className={`${props.className} btn-${props.buttonName} ${props.active ? '-active' : '-disable'} ga-${props.buttonName}`}
        style={props.active ? {cursor: 'pointer'} : {}}
        id={`btn-${props.buttonName}`}
        type={props.type}
        onClick={onClickAction}
        disabled={disabledStatus}
        value={props.buttonText}
      />
    </>
  );
}
