/*****************************************************************************************
 * Snackbar用のコンポーネント
 *
 * 主に更新完了やエラーの表示など軽微なnotificationとして使用する
 * 詳細はmaterial-uiのsnackbarを参照
 * https://material-ui.com/components/snackbars/
 ****************************************************************************************/

 /** imports */
import * as React from 'react'
import { SnackbarProvider } from 'notistack' // 右上に出る通知

interface Props {
  children?: React.ReactNode
}

/** エクスポートするJSXのコンポーネント */
export const SnackbarProviderComponent = (props: Props) => {
  return (
    <SnackbarProvider
      maxSnack={6}            // snackbarの同時出力数
      autoHideDuration={5000} // snackbarの表示時間
      anchorOrigin={{         // snackbarの表示位置（右上指定）
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      {props.children}
    </SnackbarProvider>
  );
}
